import type { Level } from '~/src/__generated__/graphql'
import type { GameParamName } from '~/src/types/entities/template/types'

/**
 * Yet it stay as separate extension array for configsForMoney as configsForMoney is used by both jackpots and templates
 */
export const configsForUnitMoney: (keyof Level | GameParamName)[] = []

export const configsForMoney: (keyof Level | GameParamName)[] = [
  'minBet',
  'maxBet',
  'activationBets',
  'fundBase',
  'fundLimit',
  'fundResetValue',
  'awardBaseMinTotalBet',
  'awardBaseMaxTotalBet',
  'hitAutoApprove',
]

export const configsForNumber: (keyof Level | GameParamName)[] = [
  'activationGames',
  'activationTime',
  'activityBreak',
  'awardWagerMultiplicator',
  'code',
  'hitsCount',
  'hits',
  'index',
  'loseSession',
  'period',
  'winRate',
  'winSession',
  'fundIncrement',
  'fundReserveIncrement',
  'fundResetReserve',
  'fundOverflowIncrement',
  'fundResetOverflow',
  'rankLimit',
  'rankHitShare',
]

export const configsForDrawTime: (keyof Level | GameParamName)[] = [
  'awardBaseMinDrawTime',
  'awardBaseMaxDrawTime',
]

export const levelFieldsConfigLabel: Partial<Record<keyof Level, string>> = {
  code: 'Level code',
  index: 'Level index',
  name: 'Level name',
  minBet: 'Min bet',
  hits: 'Hits',
  hitAutoApprove: 'Hit auto-approve',
  maxBet: 'Max bet',
  participantType: 'Participant type',
  activationTime: 'Activity time (sec)',
  awardType: 'Award type',
  activityBreak: 'Activity break (sec)',
  activationBets: 'Activity bets',
  runtime: 'Runtime',
  activationGames: 'Activity games',
  eventType: 'Event type',
  eventAmountType: 'Amount type',
  fundBase: 'Pool base',
  contribution: 'Contribution type',
  fundLimit: 'Pool limit',
  fundIncrement: 'Pool increment (%)',
  fundIncrementMethod: 'Pool increment method',
  fundResetValue: 'Pool reset value',
  awardBase: 'Award base',
  awardBaseMinTotalBet: 'Min award base (total bet)',
  awardBaseMaxTotalBet: 'Max award base (total bet)',
  awardBaseMinDrawTime: 'Min award base (draw time)',
  awardBaseMaxDrawTime: 'Max award base (draw time)',
  awardServe: 'Award serve',
  awardWagerMultiplicator: 'Award wager multiplicator',
  externalCongratulatorCode: 'External congratulator code',
  externalCode: 'External code',

  fundReserveIncrement: 'Reserve increment (%)',
  fundReserveIncrementMethod: 'Reserve increment method',
  fundResetReserve: 'Reserve pool reset (%)',

  fundOverflowIncrement: 'Overflow increment (%)',
  fundOverflowIncrementMethod: 'Overflow increment method',
  fundResetOverflow: 'Overflow pool reset (%)',

  rankLimit: 'Rank limit',
  rankScope: 'Rank scope',
  scoreStrategy: 'Score strategy',
  rankDistributionRule: 'Rank distribution rule',
  rankHitShare: 'Rank hit share (%)',
  mostValuableParticipant: 'Most valuable participant',
}

/**
 * When several fields are rendered under same label use groupingLabels
 */
export const groupingLabels: Record<string, string> = {
  awardBaseMin: 'Min award base',
  awardBaseMax: 'Max award base',
}

/**
 * Grouping level params by key
 */
export const levelGrouping: Partial<Record<keyof Level, { key: string; hide: boolean }>> = {
  awardBaseMinTotalBet: {
    key: 'awardBaseMin',
    hide: false,
  },
  awardBaseMinDrawTime: {
    key: 'awardBaseMin',
    /**
     * Temporarily hide draw time from chip list
     */
    hide: true,
  },
  awardBaseMaxTotalBet: {
    key: 'awardBaseMax',
    hide: false,
  },
  awardBaseMaxDrawTime: {
    key: 'awardBaseMax',
    /**
     * Temporarily hide draw time from chip list
     */
    hide: true,
  },
}

export const levelFieldsConfigSort: ReadonlyArray<keyof Level> = [
  // General
  'name',
  'code',
  'externalCode',
  'index',
  'runtime',
  'hits',
  'hitAutoApprove',
  'participantType',
  // Active player
  'activationTime',
  'activityBreak',
  'activationGames',
  'activationBets',
  // General
  'eventType',
  'eventAmountType',
  // Active player
  'minBet',
  'maxBet',
  // Pool settings
  'contribution',
  // Draw settings
  'awardType',
  'awardBase',
  'awardBaseMinTotalBet',
  'awardBaseMaxTotalBet',
  'awardBaseMinDrawTime',
  'awardBaseMaxDrawTime',
  // Draw settings
  'awardServe',
  'awardWagerMultiplicator',
  'externalCongratulatorCode',
  // Pool settings
  'fundBase',
  'fundLimit',
  'fundIncrement',
  'fundIncrementMethod',
  'fundResetValue',
  'fundReserveIncrement',
  'fundReserveIncrementMethod',
  'fundResetReserve',
  'fundOverflowIncrement',
  'fundOverflowIncrementMethod',
  'fundResetOverflow',
  // Hit distribution
  'rankLimit',
  'rankScope',
  'scoreStrategy',
  'rankDistributionRule',
  'rankHitShare',
  'mostValuableParticipant',
]

export const levelFieldsUcFirstValue: ReadonlyArray<keyof Level> = [
  'participantType',
  'awardType',
  'contribution',
  'fundIncrementMethod',
  'fundOverflowIncrementMethod',
  'fundReserveIncrementMethod',
]
